export function useTextUtils() {

  const cleanPhoneNumber = (phoneNumber : String) => {
    const result = phoneNumber.replace(/ /g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').trim();
    return result;
  }

  const normalizePhoneNumber = (phoneNumber : String, countryCode : String) => {
    let result = cleanPhoneNumber(phoneNumber);

    if (countryCode === 'CZ') {
      if (result.startsWith('+420')) {
        result = result.substring(4);
      }
      else if (result.startsWith('00420')) {
        result = result.substring(5);
      }
    }
  }

  return {
    cleanPhoneNumber,
    normalizePhoneNumber
  }

}