<template>
  <textarea class="text-area bg-transparent" :class="props.class" v-model="props.modelValue" @input="update($event)" @focus="internalFocus" @blur="blur" :maxlength="props.maxLength" :placeholder="props.placeholder" ref="element"></textarea>
</template>
<script setup>

const props = defineProps({
  modelValue: String,
  placeholder: String,
  class: String,
  maxLength: Number | undefined
});

const element = ref(null);

const emit = defineEmits(['update:modelValue', 'focus', 'validate']);

const internalFocus = () => {
  emit('focus');
}

const blur = ($event) => {
  emit('update:modelValue', $event.target.value);
  emit('validate');
}

const blurWithoutValidation = () => {
  element.value.blur();
}

const update = ($event) => emit('update:modelValue', $event.target.value);

const focus = () => {
  element.value.focus();
}

defineExpose({ focus, blurWithoutValidation });

</script>
<style lang="postcss">
.text-area {

  &:focus {
    @apply border-neutral-400;
  }

  &__wrapper {
    @apply relative;
  }

  &__label {
      position: absolute;
      left: 0.6rem;
      top: -0.65rem;
      @apply px-1 text-sm bg-neutral-50 text-neutral-400;
  }

  @apply w-full px-3 py-3 outline-none;
}
</style>