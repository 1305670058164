<template>
  <div :class="'validated-field ' + getClass">
    <span class="validated-field__wrapper" tabindex="0" @focus="focus" ref="wrapper" :class="[props.wrapperClass, { 'validated-field__wrapper--disabled': props.disabled }]">
      <AtomsFormsTextInput v-if="!props.multiLine" :max-length="props.maxLength" :class="props.inputClass" :type="inputTypes[props.type]" v-model="props.modelValue" :pattern="props.pattern" :placeholder="props.placeholder" @update:modelValue="valueUpdated($event)" @validate="validateRequest" @enter="submit" @escape="escape" @up="up" @down="down" @key-pressed="keyPressed" @blur="blur" @focus="inputFocus" :disabled="props.disabled" :autocomplete="props.autocomplete" ref="textInput" />
      <AtomsFormsTextArea v-if="props.multiLine" :max-length="props.maxLength" :class="props.inputClass" v-model="props.modelValue" :placeholder="props.placeholder" @update:modelValue="valueUpdated($event)" @validate="validateRequest" @focus="inputFocus" :disabled="props.disabled" ref="textInput" />
      <span v-if="props.label" class="validated-field__label">{{ props.label }}{{ props.required ? ' *' : '' }}</span>
      <div class="validated-field__icon" v-if="!props.multiLine && (!isValid || (shouldValidate && isValid && !props.showErrorOnly))">
        <AtomsImagesIcon v-if="!isValid" icon="cross" class="text-error-500" />
        <AtomsImagesIcon v-if="shouldValidate && isValid && !props.showErrorOnly" icon="checked" class="text-success-500" />
      </div>
      <MoleculesButtonsButton v-if="props.buttonText" :arrow-right="true" @click="submit">{{ props.buttonText }}</MoleculesButtonsButton>
    </span>
    <span class="validated-field__error" v-html="_errorMessage"></span>
  </div>
</template>
<script setup>

const props = defineProps({
  multiLine: Boolean,
  type: String,
  label: String,
  modelValue: String,
  pattern: String,
  required: Boolean,
  placeholder: String,
  errorMessage: String,
  disabled: String,
  buttonText: String,
  countryCode: String,
  passwordToCheck: String,
  inputClass: String,
  wrapperClass: String,
  maxLength: Number | undefined,
  outsideEu: Boolean | undefined,
  showErrorOnly: Boolean | undefined,
  autocomplete: String | undefined
});

const i18n = useI18n();
const ui = useUI();
const analytics = useBaseAnalytics();

const inputTypes = {
  'text': 'text',
  'email': 'email',
  'tel': 'tel',
  'password': 'password',
  'newPassword': 'password',
  'passwordCheck': 'password',
  'vatId': 'text',
  'ic': 'text',
  'zip': 'text'
}

const textInput = ref(null);
const wrapper = ref(null);
const isValid = ref(true);
const shouldValidate = ref(false);
const _errorMessage = ref(props.errorMessage);
const isPristine = ref(true);

let formFieldFillingStarted = false;

const getClass = computed(() => { return (shouldValidate.value ? (isValid.value ? (props.showErrorOnly ? '' : 'validated-field--valid') : 'validated-field--invalid') : '') + (props.buttonText ? ' validated-field--with-button' : '') });

const emit = defineEmits(['invalid', 'update:modelValue', 'submit', 'aresData', 'aresError', 'up', 'down', 'escape', 'keyPressed', 'blur']);

const scrollTo = (offset) => {
  ui.scrollToElement(wrapper.value, offset);
}

const focus = () => {
  textInput.value.focus();
}

const inputFocus = () => {
  if (!formFieldFillingStarted) {
    analytics.pushEvent('user_interaction', {
      interaction_name: 'form-field-filling-start',
      page_element_specification: props.placeholder,
      page_element_value: undefined,
      _clear: true
    });

    formFieldFillingStarted = true;
  }
}

const reset = () => {
  textInput.value.blurWithoutValidation();
  shouldValidate.value = false;
  isValid.value = true;
  emit('update:modelValue', '');
}

const softReset = () => {
  shouldValidate.value = false;
}

const validate = async () => {

  shouldValidate.value = true;

  let validationResult = true;

  if (props.required && (props.modelValue?.length ?? 0) === 0) {
    validationResult = false;
    _errorMessage.value = i18n.t('Políčko nemůže zůstat prázdné');
  }

  if (props.modelValue?.length > 0) {
    if (typeof props.pattern !== 'undefined') {
      validationResult &= new RegExp(`^${props.pattern}$`).test(props.modelValue);
    }
    else if ((props.type === 'newPassword' || props.type === 'passwordCheck') && props.modelValue.length < 8) {
      validationResult = false;
      _errorMessage.value = i18n.t('Heslo musí mít alespoň 8 znaků');
    }
    else if (props.type === 'passwordCheck') {
      validationResult &= props.modelValue === props.passwordToCheck;
    }
    else if (props.type === 'vatId') {

      if (props.modelValue.length < 10) {
        validationResult = false;
      }
      else {

        if (validationResult && !props.isOutsideEu && !isPristine.value) {

          const viesResponse = (await useFetch(`/api/viesValidation?vatId=${props.modelValue}`)).data.value.external.vies.check;

          if (viesResponse.error) {
            emit('aresError');
            validationResult = false;
          }
          else if (viesResponse.response) {
            validationResult &= viesResponse.response.valid;

            // prevent multiple requests
            isPristine.value = true;
          }
          else {
            validationResult = false;
          }
        }
      }
    }
    else if (props.type === 'ic' && props.countryCode === 'CZ') {

      const re = new RegExp(/^\d{8}$/);
      validationResult &= re.test(props.modelValue);

      if (validationResult && !isPristine.value) {
        const aresResponse = (await useFetch(`/api/aresValidation?ic=${props.modelValue}`)).data.value.external.ares.check;

        if (aresResponse.error) {
          emit('aresError');
        }
        else if (aresResponse.response) {

          validationResult &= aresResponse.response.valid;

          if (validationResult) {

            const aresData = {
              company: aresResponse.response.businessName,
              street: `${aresResponse.response.address.street} ${aresResponse.response.address.buildingNumber}`,
              city: aresResponse.response.address.city,
              zip: aresResponse.response.address.zip,
            }

            emit('aresData', aresData);

            // prevent multiple requests
            isPristine.value = true;
          }
        }
        else {
          validationResult = false;
        }
      }
    }
    else if (props.type === 'tel') {
      let phone = useTextUtils().cleanPhoneNumber(props.modelValue);

      if (!phone) {
        validationResult = false;
      }
      else {
        if (props.countryCode === 'CZ') {
          if (phone.startsWith('+420')) { phone = phone.replace('+420', ''); }
          else if (phone.startsWith('00420')) { phone = phone.replace('00420', ''); }

          if (phone.length !== 9) {
            validationResult = false;
          }
        }
        else if (props.countryCode === 'SK') {
          if (phone.length !== 10 || !phone.startsWith('0')) {
            validationResult = false;
          }
        }
        else if (phone[0] !== "+" && !phone.startsWith('00')) {
          validationResult = false;
        }
      }

      var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\.\/0-9]*$/g;
      validationResult &= re.test(phone);
    }
    else if (props.type === 'zip') {
      const normalizedZip = props.modelValue.replaceAll(' ', '').trim();

      if (props.countryCode === 'CZ') {
        const re = new RegExp(/^[1-7]\d{4}$/);
        validationResult = re.test(normalizedZip);
      }
      else if (props.countryCode === 'SK') {
        const re = new RegExp(/^[089]\d{4}$/);
        validationResult = re.test(normalizedZip);
      }
      else {
        validationResult = normalizedZip.length >= 4;
      }
    }
    else if (props.type === 'email') {
      validationResult &= new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(props.modelValue);

      const invalidDomains = [
        'seznam.cu',
        'senam.cu',
        'senam.cz',
        'gmail.cz',
        'email.com',
        'emal.cz',
        'sezmam.cz',
        'seznam.cy',
        'email.cy',
        'seznan.cz',
        'seynam.cz',
        'seynam.cy',
        'seznan.cz',
        'sezmam.cz',
        'ceznam.cz',
        'gmal.com',
        'sezna.cz',
        'gamil.com',
        'seznam.cz.cz',
        'seznem.cz',
        'seznam.com'
      ];

      for (let i in invalidDomains) {
        if (props.modelValue.indexOf('@') > -1 && props.modelValue.split('@')[1].indexOf(invalidDomains[i]) > -1) {
          validationResult = false;
          break;
        }
      }
    }

    if (typeof props.errorMessage !== 'undefined' && props.errorMessage) {
      _errorMessage.value = props.errorMessage;
    }
  }

  if (!validationResult) {
    emit('invalid');
  }

  analytics.pushEvent('user_interaction', {
    interaction_name: 'form-field-filling-' + (validationResult ? 'success' : 'error'),
    page_element_specification: props.placeholder,
    page_element_value: props.modelValue,
    _clear: true
  });

  isValid.value = validationResult;

  return validationResult;
}

const validateRequest = async () => {
  await validate();
}

const valueUpdated = value => {
  emit('update:modelValue', value);
}

watch (() => props.modelValue, async (newValue, oldValue) => {

  if (newValue !== oldValue) {
    isPristine.value = false;

    // validate always when input has changed and the previous state was invalid
    if (!isValid.value) {
      nextTick(() => {
        validate();
      });
    }
  }
});

const submit = async () => {

  if (await validate()) {
    emit('submit');

    shouldValidate.value = false;
  }

}

const up = () => {
  emit('up');
}

const down = () => {
  emit('down');
}

const escape = () => {
  emit('escape');
}

const keyPressed = (code) => {
  emit('keyPressed', code);
}

const blur = () => {
  emit('blur');
}

defineExpose({ scrollTo, validate, reset, softReset, focus });

</script>
<style lang="postcss">
.validated-field {

  flex: 1;
  @apply my-6;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    @apply border-2 border-neutral-200 transition-all;

    &:focus-within {
      @apply border-neutral-400;
    }

    &--disabled {

      input {
        @apply text-neutral-300 cursor-not-allowed;
      }

      &:focus-within {
        @apply border-neutral-200;
      }
    }

    .validated-field__label {
      position: absolute;
      left: 0.5rem;
      top: -0.75rem;
      @apply px-1 text-sm bg-neutral-50 text-neutral-400;
    }
  }

  input {
    flex: 1;
    border: 0;
    min-width: 150px;
    @apply p-3;
  }

  &__error {
    display: block;
    margin-left: 0.75rem;
    max-height: 0;
    overflow: hidden;
    font-size: 0.8rem;
    @apply text-error-500 transition-all;
  }

  &__icon {
    margin-right: 1rem;
    margin-top: -0.5rem;
    @apply text-xl;
  }

  &--valid {

    .validated-field__wrapper {
      @apply border-success-500;
    }

    .validated-field__label {
      @apply text-success-500;
    }
  }

  &--invalid {

    .validated-field__wrapper {
      @apply border-error-500;
    }

    .validated-field__label {
      @apply text-error-500;
    }

    .validated-field__error {
      max-height: 4rem;
    }
  }

  &--with-button {
    .validated-field {
      &__wrapper {
        @apply flex-wrap tablet:flex-nowrap pt-0;

        .text-input {
          @apply tablet:pt-4;
        }

        .validated-field__placeholder {
          @apply tablet:top-[1.1rem];
        }

        .button {
          @apply flex-1 flex-grow-0 tablet:flex-none;
          margin: -2px;
        }
      }
    }
  }
}
</style>